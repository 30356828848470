import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../../../constants/apiConstants';
import axios from 'axios'

export default class PurchaseFormServices {

  static async getPlanningDriverinfo(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getVendorDetails/` + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async getPlanningDriverinfoBytransport(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getPlanningDriverinfoBytransport/` + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchDriverData(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(
      API_BASE_URL + `/findSingleContactData?id=${id}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return response.data;

  }

  static async getVendor() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findAllVendorforPo?isactive=` + 1, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

 

  static async fetchAllVendor() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findAllVendor?status=active`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchPruchasedOrderLine(recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getPurchasedOrderLine?recordID=` + recordID, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchTransportPOCount(invoiceRefnumber) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/transportPOCount?invoiceRefnumber=` + invoiceRefnumber, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async getVendorById(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findSingleVendorData?id=` + id + `&status=` + [1, 0], {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data;
  }


  static async getData(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getPurchaseOrderInfo?id=` + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateDistance(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.put(API_BASE_URL + `/updateGoogleDistance` ,{ id: id }, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateOverrideDistance(obj) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME)
    const response = await axios.put(API_BASE_URL + `/updateOverrideDistance` ,{ obj }, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  

  static async getAllNotes(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getAllNotes?id=` + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async uploadAttachment(file, recordID, tableName) {
    var formData = new FormData();
    formData.append('avatar', file);
    formData.append('tableName', tableName);
    formData.append('recordID', recordID);
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    try{
    const response = await axios.post(API_BASE_URL + `/uploadFile`, formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }catch(e){
    return e.response;
  }
  }

  static async fetchAttachment(object) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getUploadedFile?recordID=${object.recordID}&tableName=${object.tableName}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async deleteAttachment(object) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.delete(API_BASE_URL + `/deleteUploadedFile`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      data: object
    });
    return response.data;
  }

  static async deleteLineItemPurchaseOrder(lineItemArray) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.delete(API_BASE_URL + `/deleteLineItemPurchaseOrder`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      data: lineItemArray
    });
    return response.data;
  }

  static async getPurchaseOrderStatus(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/checkOrderStatus?orderId=` + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data;
  }

  static async getPicklocationByName(pickupLocation, pickupAddress1, recordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.post(API_BASE_URL + `/getPicklocation`, {
      pickupLocation, pickupAddress1, recordID
    }, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchAllDriverByVendor(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findSingleVendorData?id=` + id + `&status=` + [1, 0], {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchActiveDriverByVendor(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/findSingleVendorData?id=` + id + `&status=` + 1, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async getOverrideStatus(id) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/checkOverrideVendor?id=` + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchItems() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getItem`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async transportCount(refnumber, invoicePorecordID) {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/geTransportCount?invoiceRefNumber=` + refnumber + `&invoicePorecordID=` + invoicePorecordID, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async fetchFuelSurcharge() {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.get(API_BASE_URL + `/getFuelSurchargeRate`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }

  static async updateBudget(recordID) {
    const response = await axios.post(API_BASE_URL + `/updateBudget`, { recordID }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
    });
    return response.data;
  }

  static async deletePurchasedOrderLine(recordID) {
    console.log("rfwerwerwerwerewr", recordID);
    const response = await axios.delete(API_BASE_URL + `/deletePurchasedOrderLine`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME),
      },
      data: { recordID }
    });
    return response.data;
  }

  static async upldatePlanningDriver(driverId, id, driverName=null) {
    const obj={driverId: driverId};
    if(driverName){
      obj.driverName=driverName;
    }
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    const response = await axios.put(API_BASE_URL + `/updatePlanningDriver?id=` + id, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  }
}

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PurchaseFormServices from '../../PurchaseOrder/Services/PurchaseFormServices';
import { ToastContainer, toast } from 'react-toastify';

export default function Dropzone(props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [fileLoding, setFileLoding] = useState(false);
  const [fileErrorMsg, setFileErrorMsg] = useState('');


  console.log("checkProps", props);

  const files = props.allfiles.map(data =>
    <div class="upload-section fileupload">
      <a target="_blank" href={data.location}>
        <div class="upload-card">
          <div class="">
            <div class="d-flex align-items-baseline">
              {/* <input type="checkbox" name="" id="" /> */}
              <div class="img-box">
                {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALcAAAETCAMAAABDSmfhAAAAMFBMVEX09PTMzMzx8fHPz8/u7u7JycnY2NjT09P39/fm5ubq6urR0dHf39/W1tbt7e3c3NzOM/f9AAAB7UlEQVR4nO3a626qQBSAUS4dLmrr+79t0XoBtC3MOSnbZK2fCsnnZBwYsSgAAAAAAAAAAAAAAAAAAAAAAAAAAH7zlmPr6OJQlzn2h22z31NWdlmmZsvsQ272EL7liOdNki/77bKrYbjTR8aJH6fw6r/3LHXqLruMEzvdOSbd3WDpiYG6+/eybJeuEXG62/OKmOpl39Iw3e1tIV9UE6W7v2WnZskkD9LdtaPr4JPjHj5KkO5ifP3ePR53nCe+RneT5o1Rukf3KekhZ8gu6+mrUbqPo9ul2WTumvN3dhoepbu6ryf97Jjm8tZ+nBmlu9hd6tJhOtxdc/tE4xEP0929tWVKZd3PZkkz3ljcQ8N0n8r7/uEiP8kejXig7ie6ZraNq6/7+NDdD9n38MjdT7JvUyVcd7W7bSOeZV/Dw3W36bqiPM++5AbrrtrThef8wrfZZV2E6z7fzp7Cv5kkX8J1X+7Ch/CfssN13zcP7XfJEbt/jo3aXS3OjtW9PDtU94rsSN1rsgN1V2uydet+se7il9Ko3dV+jTjd52cNiwXqXutlu9P23btq9b8Kqn7b8S72qxaSqXa77O5fns/Pf5L7U8fs/0McN6we9G2do53/3PzXuqLKseUcAQAAAAAAAAAAAAAAAAAAAAAAAABexCfv9xRvgCHcRgAAAABJRU5ErkJggg==" alt="" />
               */}
                <i class="fa fa-file-image-o" aria-hidden="true"></i>
              </div>
              <div class="upload-heading cardHeading">
                <h4>{data.filename}</h4>
                <label>A few swecond ago by logistics app</label>
              </div>
            </div>
          </div>
        </div>
      </a>
      <a href="javascript:void(0)" class="close-icon" onClick={props.deleteAttachement}><i valueinfo={data.token} class="fa fa-times" aria-hidden="true"></i></a>
    </div>
  );

  const filesUploading = acceptedFiles.map((data) =>
    <div class="upload-section">
      <div class="upload-card">
        <div class="upload-inner">
          <div class="d-flex align-items-baseline">
            <div class="img-box">
              <img src="https://icons.iconarchive.com/icons/icons8/windows-8/512/Very-Basic-Image-File-icon.png" />
            </div>
            <div class="upload-heading">
              <h4>{data.name}</h4>
              <label>Uploading..............</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


  useEffect(() => {
    acceptedFiles.map(async (file) => {
      console.log("sadasd", file)
      if(file.size>50*1024 * 1024){
        setFileErrorMsg("File size exceeds 50 MB limit!"); 
        return 
      }
      // Allowing file type
      var allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.gif|\.tiff|\.psd|\.eps|\.ai|\.indd|\.raw|\.pdf|\.docs|\.docx)$/i;

      if (!allowedExtensions.exec(file.name)) {
        setTimeout(() => {
          toast.error('Invalid File Type', {
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        });
        file.value = '';
        return false;
      }
      setFileErrorMsg('')
      setFileLoding(true);
      const response = await PurchaseFormServices.uploadAttachment(file, props.recordID, props.tableName);
   
      console.log('error--',response)
      if (response.location)
        props.attachment(response.location, response.token, response.filename);
     else {
          setFileErrorMsg('An unexpected error occurred.'); // Handle other errors
        }
        
      

      setFileLoding(false);
    })
  }, [acceptedFiles]);

  return (
    <>
      <div class="upload-main">{files}</div>
      {
        (fileLoding) ?
          <div class="upload-main">{filesUploading}</div>
          :
          ''
      }
      {
        (fileErrorMsg) ?
          <div class="upload-main text-center"><i class="text-danger">{fileErrorMsg}</i></div>
          :
          ''
      }

      <div class="drag-section" {...getRootProps({ className: 'dropzone' })}>
        <div class="drag-inner">
          <div class="drag-heading">
            <h5>Drag files here or <span className='anchor'>click to browse</span></h5>
            <i>individual file size limit is 50 MB</i>
          </div>

          <input {...getInputProps()} name={'attachment'} />
        </div>
      </div>
    </>
  );
}